const dateFormater = (date) => {
    const formatedDate =
        date.getUTCFullYear() +
        "-" +
        ("0" + (date.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getUTCDate()).slice(-2) +
        " " +
        ("0" + date.getUTCHours()).slice(-2) +
        ":" +
        ("0" + date.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + date.getUTCSeconds()).slice(-2);
    return formatedDate;
};

const localDateFormater = (date, timezone) => {
    const formatedDate =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        date.toLocaleDateString("en-US", { timeZone: timezone, timeZoneName: "short" }).slice(-3);

    return formatedDate;
};

class RealTimeMeringPlotParser {
    static getEmptyPlot() {
        return {
            plotTitle: "",

            y1_title: "Voltage (V)",
            y2_title: "Current (A)",

            first_plot_heading: "Voltage Waveforms",
            second_plot_heading: "Current Waveforms",

            x_title: "Time (sec)",

            legend_x11: "V L-N",
            legend_x12: "Va",
            legend_x13: "Vb",
            legend_x14: "Vc",

            legend_x21: "I L-N",
            legend_x22: "Ia",
            legend_x23: "Ib",
            legend_x24: "Ic",

            Time: [],

            x11: [],
            x12: [],
            x13: [],
            x14: [],

            x21: [],
            x22: [],
            x23: [],
            x24: [],
        };
    }

    static getPlotDataLL(plotData, plotMetaData, timezone) {
        let time = plotData.displayTimestamp.slice(0, -3);
        if (timezone.value === "UTC") {
            time = dateFormater(new Date(plotData.timestamp * 1000));
        }

        const plotDataResponse = {
            plotTitle:
                plotMetaData.selectedEquipment.plot_title_node_label +
                ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
                "<br>Date: " +
                localDateFormater(new Date(), timezone.value),

            y1_title: "Voltage (V)",
            y2_title: "Current (A)",

            first_plot_heading: "",
            second_plot_heading: "",

            x_title: "Date - Time",

            legend_x11: "Voltage L-L",
            legend_x12: "Voltage-AB",
            legend_x13: "Voltage-BC",
            legend_x14: "Voltage-CA",

            legend_x21: "Current",
            legend_x22: "Current-A",
            legend_x23: "Current-B",
            legend_x24: "Current-C",

            Time: time,

            x11: plotData.voltage,
            x12: plotData.voltage1,
            x13: plotData.voltage2,
            x14: plotData.voltage3,

            x21: plotData.current,
            x22: plotData.current1,
            x23: plotData.current2,
            x24: plotData.current3,
        };
        if (plotMetaData.selectedEquipment.location_node_id.split(".")[1] == 1) {
            plotDataResponse.legend_x11 = "";
            plotDataResponse.legend_x12 = "V-DC";
            plotDataResponse["x11"] = null;
            plotDataResponse["x12"] = plotData.fieldVoltage;
            // making 0 other phases
            plotDataResponse["x13"] = null;
            plotDataResponse["x14"] = null;

            plotDataResponse.legend_x22 = "I-DC";
            // plotDataResponse['x21'] = plotData.current1
            plotDataResponse["x22"] = plotData.fieldCurrent;
            // making 0 other phases
            plotDataResponse["x23"] = null;
            plotDataResponse["x24"] = null;

            plotDataResponse["table"] = {
                columns: [
                    { dataField: "displayTimestamp", text: "Timestamp" },
                    { dataField: "voltage", text: "RMS Voltage" },
                    { dataField: "current", text: "RMS Current" },
                    { dataField: "hp", text: "HP" },
                    { dataField: "lineFrequency", text: "LF" },
                    { dataField: "powerKw", text: "Power kW" },
                    { dataField: "powerFactor", text: "Power Factor" },
                ],
                data: [
                    {
                        displayTimestamp: plotData.displayTimestamp,
                        voltage: plotData.fieldVoltage?.toFixed(2),
                        current: plotData.fieldCurrent?.toFixed(2),
                        hp: "---",
                        lineFrequency: "---",
                        powerKw: "---",
                        powerFactor: "---",
                    },
                ],
                keyField: "displayTimestamp",
            };
        } else if (plotMetaData.selectedEquipment.eq_type === "dc") {
            plotDataResponse["x12"] = plotData.voltage;
            plotDataResponse["x22"] = plotData.current;

            plotDataResponse.legend_x11 = "";
            plotDataResponse.legend_x12 = "V-DC";
            plotDataResponse.legend_x11 = "";
            plotDataResponse.legend_x13 = "";
            plotDataResponse.legend_x14 = "";
            plotDataResponse.legend_x21 = "";
            plotDataResponse.legend_x22 = "I-DC";
            plotDataResponse.legend_x23 = "";
            plotDataResponse.legend_x24 = "";

            plotDataResponse.x11 = null;
            plotDataResponse.x13 = null;
            plotDataResponse.x14 = null;
            plotDataResponse.x21 = null;
            plotDataResponse.x23 = null;
            plotDataResponse.x24 = null;
        }
        if (plotMetaData.selectedEquipment.eq_type_sub === "v1") {
            plotDataResponse["x11"] = null;
            plotDataResponse["x12"] = plotData.voltage;
            // making 0 other phases
            plotDataResponse["x13"] = null;
            plotDataResponse["x14"] = null;

            plotDataResponse.legend_x11 = "";
            plotDataResponse.legend_x12 = "V";
            plotDataResponse.legend_x13 = "";
            plotDataResponse.legend_x14 = "";
        }
        return plotDataResponse;
    }

    static getPlotDataLN(plotData, plotMetaData, timezone) {
        let time = plotData.displayTimestamp.slice(0, -3);
        if (timezone.value === "UTC") {
            time = dateFormater(new Date(plotData.timestamp * 1000));
        }

        const plotDataResponse = {
            plotTitle:
                plotMetaData.selectedEquipment.plot_title_node_label +
                ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
                "<br>Date: " +
                localDateFormater(new Date(), timezone.value),

            y1_title: "Voltage (V)",
            y2_title: "Current (A)",

            first_plot_heading: "Voltage Waveforms",
            second_plot_heading: "Current Waveforms",

            x_title: "Date - Time",

            legend_x11: "Voltage L-N",
            legend_x12: "Voltage-A",
            legend_x13: "Voltage-B",
            legend_x14: "Voltage-C",

            legend_x21: "Current",
            legend_x22: "Current-A",
            legend_x23: "Current-B",
            legend_x24: "Current-C",

            Time: time,

            x11: plotData.voltage,
            x12: plotData.voltage1,
            x13: plotData.voltage2,
            x14: plotData.voltage3,

            x21: plotData.current,
            x22: plotData.current1,
            x23: plotData.current2,
            x24: plotData.current3,
        };
        if (plotMetaData.selectedEquipment.eq_type_sub === "i1") {
            plotDataResponse.legend_x11 = "";
            plotDataResponse.legend_x12 = "V";
            plotDataResponse.legend_x13 = "";
            plotDataResponse.legend_x14 = "";

            plotDataResponse.legend_x21 = "";
            plotDataResponse.legend_x22 = "I";
            plotDataResponse.legend_x23 = "";
            plotDataResponse.legend_x24 = "";

            plotDataResponse["x11"] = null;
            plotDataResponse["x12"] = plotData.voltage;
            plotDataResponse["x13"] = null;
            plotDataResponse["x14"] = null;

            plotDataResponse["x21"] = null;
            plotDataResponse["x22"] = plotData.current;
            plotDataResponse["x23"] = null;
            plotDataResponse["x24"] = null;
        }
        return plotDataResponse;
    }
}

class OneHourParser {
    static parameters = {
        //Dictionary of keys/fields to search for
        Voltage: {
            plotFields: ["voltage", "voltage_ab", "voltage_bc", "voltage_ca"],
            legendFields: ["Voltage L-L", "Voltage-AB", "Voltage-BC", "Voltage-CA"],
            tableField: "voltage",
        },
        Current: {
            plotFields: ["current", "current_a", "current_b", "current_c"],
            legendFields: ["Current", "Current-A", "Current-B", "Current-C"],
            tableField: "current",
        },
        "Line Frequency": {
            plotFields: ["line_frequency"],
            legendFields: ["Line Frequency"],
            tableField: "line_frequency",
        },
        "Voltage Imbalance": {
            plotFields: ["voltage_imbalance"],
            legendFields: ["Voltage Imbalance"],
            tableField: "voltage_imbalance",
        },
        "Current Imbalance": {
            plotFields: ["current_imbalance"],
            legendFields: ["Current Imbalance"],
            tableField: "current_imbalance",
        },
        "Max Voltage Imbalance": {
            plotFields: ["max_voltage_cycle_imbalance"],
            legendFields: ["Max Voltage Imbalance"],
            tableField: "max_voltage_cycle_imbalance",
        },
        "Max Current Imbalance": {
            plotFields: ["max_current_cycle_imbalance"],
            legendFields: ["Max Current Imbalance"],
            tableField: "max_current_cycle_imbalance",
        },
        "Power Factor": {
            plotFields: ["power_factor"],
            legendFields: ["Power Factor"],
            tableField: "power_factor",
        },
        "Power kW": {
            plotFields: ["power_kw"],
            legendFields: ["Power kW"],
            tableField: "power_kw",
        },
    };

    /*
	Inputs: 
		plotData: plot_data object returned within the 'getHourlyTrendData' api call
		plotMetaData: metaData containing selectedEquipment, startDate, endDate, and selectedVoltageType.value - Typically set in tabData and given to the plot as a prop
	Outputs:
		Object containing layout and data parameters to be used by a plotly plot
	*/
    static getPlotResponse(plotData, plotMetaData) {
        //console.log(plotData)
        const parameters = this.parameters;
        const plotTitle =
            plotMetaData.selectedEquipment.plot_title_node_label +
            ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
            "<br>Date: " +
            plotMetaData.startDate +
            " to " +
            plotMetaData.endDate +
            " " +
            new Date()
                .toLocaleDateString("en-US", { timeZone: plotMetaData.timezone, timeZoneName: "short" })
                .slice(-3);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: "Date - Time" },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: { font: { size: 22 }, automargin: true, x: 0.07, align: "top", text: plotTitle },
            legend: { font: { size: 18 } },
            showlegend: true,
        };

        const markerColors = ["green", "black", "red", "blue"];
        const x = plotData.time;

        var data = [];
        var annotations = [];
        var count = 0;

        for (const subplot in parameters) {
            if (
                plotData[parameters[subplot]["plotFields"][0]] == null ||
                plotData[parameters[subplot]["plotFields"][0]].length <= 0
            )
                continue;

            count++;

            //Format if Y-axis name is too long
            var yTitle = subplot;
            if (yTitle.length > 20) {
                //replace the space at arbitrary point near middle of text with a \n
                const index = yTitle.indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                yTitle = subplot.substring(0, index) + "<br>" + subplot.substring(index + 1);
            }

            layout[`yaxis${count}`] = {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: yTitle },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            };

            const subplotTitle = {
                text: subplot,
                font: { size: 18 },
                xref: "paper",
                yref: `y${count} domain`,
                x: 0.5,
                y: 0.99,
                yanchor: "bottom",
                showarrow: false,
            };
            annotations.push(subplotTitle);
            for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                if (plotData[field] == null || plotData[field].length <= 0) continue;

                const trace = {
                    x: x,
                    y: plotData[field],
                    yaxis: `y${count}`,
                    type: "scatter",
                    name: parameters[subplot]["legendFields"][idx],
                    marker: { color: markerColors[idx] },
                    connectgaps: true,
                };
                data.push(trace);

                //Add NP + SF trace for current plot
                if (field == "current") {
                    const eq = plotMetaData.selectedEquipment;
                    const yVal = parseFloat((eq.np_sf == 0.1 ? 1.15 : eq.np_sf) * eq.np_current);
                    const npsf_trace = {
                        x: [x[0], x.slice(-1)[0]],
                        y: [yVal, yVal],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: "NP + SF",
                        marker: { color: "black" },
                    };
                    data.push(npsf_trace);
                }
            }
        }

        layout["grid"] = { rows: count, columns: 1 };
        layout["height"] = count == 1 ? 400 : count * 300;
        layout["annotations"] = annotations;
        return { layout, data };
    }

    /*
	Inputs: Raw table data from 'getHourlyTrendData' api call 
	Outputs: Object containing columns, table data, with a keyfield for a bootstrap table
	*/
    static getTableResponse(tableData) {
        const parameters = this.parameters;

        const columns = [{ dataField: "measurement", text: "" }];

        const avg_data = { measurement: "Average" };
        const min_data = { measurement: "Min" };
        const max_data = { measurement: "Max" };

        for (const subplot in parameters) {
            const field = parameters[subplot]["tableField"];
            const avg = tableData[`${field}_avg`];
            const min = tableData[`${field}_min`];
            const max = tableData[`${field}_max`];
            if (avg != null) {
                columns.push({ dataField: field, text: subplot });
                avg_data[field] = avg;
                min_data[field] = min;
                max_data[field] = max;
            }
        }

        const data = [avg_data, min_data, max_data];

        return {
            columns,
            data,
            keyField: "measurement",
        };
    }
}

const average = (array) => {
    var sum = 0;
    for (const num of array) {
        if (isNaN(num) || num == null) continue;
        sum += num;
    }
    const avg = sum / array.length;
    return isNaN(avg) ? "---" : avg.toFixed(2);
};

const minimum = (array) => {
    var min = Infinity;
    for (const num of array) {
        if (isNaN(num) || num == null) continue;
        if (num < min) min = num;
    }
    return min == Infinity ? "---" : min.toFixed(2);
};

const maximum = (array) => {
    var max = -Infinity;
    for (const num of array) {
        if (isNaN(num) || num == null) continue;
        if (num > max) max = num;
    }
    return max == -Infinity ? "---" : max.toFixed(2);
};

class OneSecParser {
    static parseData(plotData, metaData) {
        const parameters = {
            Voltage: {
                plotName: "Voltage",
                yAxisName: "Voltage (V)",
                plotFields: ["voltage"],
                legendNames: ["Voltage"],
                traceColor: ["blue"],
                tableName: "Voltage",
                tableField: "voltage",
            },
            Current: {
                plotName: "Current",
                yAxisName: "Current (A)",
                plotFields: ["current"],
                legendNames: ["Current"],
                traceColor: ["orange"],
                tableName: "Current",
                tableField: "current",
            },
            Frequency: {
                plotName: "Frequency",
                yAxisName: "Frequency (Hz)",
                plotFields: ["frequency"],
                legendNames: ["Frequency"],
                traceColor: ["green"],
                tableName: "Frequency",
                tableField: "frequency",
            },
            "V/I": {
                plotName: "V/I",
                yAxisName: "V/I (Ω)",
                plotFields: ["v_i"],
                legendNames: ["V/I"],
                traceColor: ["green"],
                tableName: "V/I",
                tableField: "v_i",
            },
            "I/Ifield": {
                plotName: "I/I<sub>field</sub>",
                yAxisName: "I/I<sub>field</sub>",
                plotFields: ["I/Ifield"],
                legendNames: ["I/I<sub>field</sub>"],
                traceColor: ["red"],
                tableName: (
                    <>
                        I/I<sub>field</sub>
                    </>
                ),
                tableField: "I/Ifield",
            },
            "Active Power (kW)": {
                plotName: "Active Power (kW)",
                yAxisName: "kW",
                plotFields: ["kw"],
                legendNames: ["kW"],
                traceColor: ["green"],
                tableName: "kW",
                tableField: "kw",
            },
            "Apparent Power (kVA)": {
                plotName: "Apparent Power (kVA)",
                yAxisName: "kVA",
                plotFields: ["kva"],
                legendNames: ["kVA"],
                traceColor: ["green"],
                tableName: "kVA",
                tableField: "kva",
            },
            "Power Factor": {
                plotName: "Power Factor",
                yAxisName: "",
                plotFields: ["power_factor"],
                legendNames: ["Power Factor"],
                traceColor: ["green"],
                tableName: "Power Factor",
                tableField: "power_factor",
            },
            "Reactive Power (kVAR)": {
                plotName: "Reactive Power (kVAR)",
                yAxisName: "kVAR",
                plotFields: ["kvar"],
                legendNames: ["kVAR"],
                traceColor: ["green"],
                tableName: "kVAR",
                tableField: "kvar",
            },
        };

        const selectedParameters = metaData.selectedParameters.map((param) => param.value);

        //-------Setup Plot layout-------//
        const plotTitle =
            metaData.selectedNodes
                .map((eq) => {
                    return (
                        eq.plot_title_node_label +
                        ` (${eq.np_voltage}V, ${eq.np_current}A, ${eq.np_rpm}RPM, ${eq.np_hp}HP)`
                    );
                })
                .join("<br>") +
            "<br>Date: " +
            metaData.startDate +
            " to " +
            metaData.endDate +
            " " +
            new Date()
                .toLocaleDateString("en-US", {
                    timeZone: metaData.timezone,
                    timeZoneName: "short",
                })
                .slice(-3);

        const selectedNodeCount = Object.keys(metaData.selectedNodes).length;
        const subplotCount = Object.keys(selectedParameters).length;

        const titleHeight = 27 + selectedNodeCount * 28.6;
        const titleMargin = titleHeight + 44.4;
        const plotHeight = (subplotCount == 1 ? 400 : subplotCount * 300) + titleMargin;
        const titleLocation = 1 - 30 / plotHeight;
        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                text: plotTitle,
                y: titleLocation,
                yref: "container",
                automargin: true,
            },
            legend: {
                font: { size: 18 },
            },
            showlegend: true,
            margin: {
                t: titleMargin,
            },
        };

        var annotations = [];
        var count = 0;
        for (const subplot of selectedParameters) {
            count++;

            //Format if Y-axis name is too long
            //Replace ignores any html tags so only display name is measured
            var yTitle = parameters[subplot]["yAxisName"];
            if (yTitle.replace(/(<([^>]+)>)/gi, "").length > 20) {
                //replace the space at arbitrary point near middle of text with a break
                const index = yTitle.replace(/(<([^>]+)>)/gi, "").indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                yTitle = yTitle.substring(0, index) + "<br>" + yTitle.substring(index + 1);
            }

            layout[`yaxis${count}`] = {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: yTitle },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            };

            const subplotTitle = {
                text: parameters[subplot]["plotName"],
                font: { size: 18 },
                xref: "paper",
                yref: `y${count} domain`,
                x: 0.5,
                y: 0.99,
                yanchor: "bottom",
                showarrow: false,
            };
            annotations.push(subplotTitle);
        }
        layout["grid"] = { rows: subplotCount, columns: 1 };
        layout["height"] = plotHeight;
        layout["annotations"] = annotations;

        //-------Create each trace and add to plot---------//
        const data = [];
        var nodeCount = 0;
        const colorway = ["blue", "orange", "green", "purple"];
        for (const node of metaData.selectedNodes) {
            const location_node_id = node.location_node_id;
            if (
                plotData[location_node_id] == null ||
                plotData[location_node_id]["time"] == null ||
                plotData[location_node_id]["time"].length == 0
            )
                continue;

            //-------Pre-Processing-------//
            for (const param of selectedParameters) {
                if (param == "V/I") {
                    //Create V/I data

                    const plotField = parameters[param]["plotFields"][0];
                    if (
                        plotData[location_node_id]["voltage"] == null ||
                        plotData[location_node_id]["current"] == null
                    ) {
                        console.log(`Missing data requirements for the V/I plot for ${location_node_id}`);
                        continue;
                    }

                    const v_i = Array(plotData[location_node_id]["voltage"].length);
                    const v_noise = metaData.selectedEquipment.v_noise;
                    const i_noise = metaData.selectedEquipment.i_noise;
                    for (var i = 0; i < plotData[location_node_id]["voltage"].length; i++) {
                        //Finds V/I and rounds to 3 digits
                        if (
                            plotData[location_node_id]["voltage"][i] < v_noise ||
                            plotData[location_node_id]["current"][i] < i_noise
                        ) {
                            v_i[i] = 0;
                        } else {
                            v_i[i] =
                                Math.round(
                                    (plotData[location_node_id]["voltage"][i] /
                                        plotData[location_node_id]["current"][i]) *
                                        1e3
                                ) / 1e3;
                        }
                    }
                    plotData[location_node_id][plotField] = v_i;
                } else if (param == "Voltage" && metaData.additionalNodesFlag == false) {
                    const postfix =
                        metaData.selectedEquipment.eq_type == "dc" ? "-DC" : ` ${metaData.selectedVoltageType}`;
                    parameters["Voltage"]["legendNames"][0] += postfix;
                    parameters["Voltage"]["tableName"] += postfix;
                } else if (param == "Current" && metaData.additionalNodesFlag == false) {
                    const postfix = metaData.selectedEquipment.eq_type == "dc" ? "-DC" : "";
                    parameters["Current"]["legendNames"][0] += postfix;
                    parameters["Current"]["tableName"] += postfix;
                } else if (param == "I/Ifield") {
                    //Create I/Ifield data
                    const plotField = parameters[param]["plotFields"][0];
                    if (
                        plotData[location_node_id]["field_current"] == null ||
                        plotData[location_node_id]["current"] == null
                    ) {
                        console.log("Missing data requirements for the V/I plot");
                        continue;
                    }

                    const i_noise = metaData.selectedEquipment.i_noise;
                    const i_ifield = Array(plotData[location_node_id]["current"].length);
                    for (var i = 0; i < plotData[location_node_id]["current"].length; i++) {
                        if (plotData[location_node_id]["field_current"][i] == 0) {
                            plotData[location_node_id]["field_current"][i] = 0.00001;
                        }

                        i_ifield[i] =
                            plotData[location_node_id]["current"][i] > i_noise
                                ? Math.round(
                                      (plotData[location_node_id]["current"][i] /
                                          plotData[location_node_id]["field_current"][i]) *
                                          1e3
                                  ) / 1e3
                                : 0;
                    }
                    plotData[location_node_id][plotField] = i_ifield;
                }
            }

            const x = plotData[location_node_id].time;

            //Create plot data
            var count = 0;
            for (const subplot of selectedParameters) {
                if (plotData[location_node_id][parameters[subplot]["plotFields"][0]] == null) continue;
                count++;
                for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                    if (plotData[location_node_id][field] == null) continue;

                    const trace = {
                        x: x,
                        y: plotData[location_node_id][field],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: metaData.additionalNodesFlag ? node.label : parameters[subplot]["legendNames"][idx],
                        marker: {
                            color: metaData.additionalNodesFlag
                                ? colorway[nodeCount % colorway.length]
                                : parameters[subplot]["traceColor"][idx],
                        },
                        legendgroup: metaData.additionalNodesFlag
                            ? `${location_node_id}`
                            : parameters[subplot]["legendNames"][idx],
                        showlegend: metaData.additionalNodesFlag ? count == 1 : true,
                    };
                    const fillerTrace = {
                        x: x,
                        y: plotData[location_node_id][field],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: metaData.additionalNodesFlag ? node.label : parameters[subplot]["legendNames"][idx],
                        marker: {
                            color: metaData.additionalNodesFlag
                                ? colorway[nodeCount % colorway.length]
                                : parameters[subplot]["traceColor"][idx],
                        },
                        legendgroup: metaData.additionalNodesFlag
                            ? `${location_node_id}`
                            : parameters[subplot]["legendNames"][idx],
                        showlegend: false,
                        line: {
                            dash: "2px,4px",
                            width: "2",
                        },
                        connectgaps: true,
                    };
                    data.push(trace, fillerTrace);

                    //Add NP + SF trace for current plot
                    if (field == "current" && metaData.additionalNodesFlag == false) {
                        const eq = metaData.selectedEquipment;
                        const yVal = parseFloat((eq.np_sf == 0.1 ? 1.15 : eq.np_sf) * eq.np_current);
                        const npsf_trace = {
                            x: [x[0], x.slice(-1)[0]],
                            y: [yVal, yVal],
                            yaxis: `y${count}`,
                            type: "scatter",
                            name: "NP + SF",
                            marker: { color: "black" },
                        };
                        data.push(npsf_trace);
                    }
                }
            }
            nodeCount++;
        }

        const plot = { layout, data };

        //----Creating Table----//
        function tableFormat(cell, row, rowIndex, colIndex) {
            if (rowIndex % 3) {
                return { style: { display: "none" } };
            } else {
                return { rowSpan: 3 };
            }
        }
        const columns = [
            { dataField: "label", text: "Node SN", attrs: tableFormat },
            { dataField: "measurement", text: "" },
        ];

        //Create columns
        for (const subplot of selectedParameters) {
            const text = parameters[subplot]["tableName"];
            const field = parameters[subplot]["tableField"];

            columns.push({ dataField: field, text: text });
        }

        //Create data
        const tableData = [];
        for (const node of metaData.selectedNodes) {
            const label = node.label;
            const location_node_id = node.location_node_id;

            if (plotData[location_node_id] == null) continue;

            const avg_data = { label: label, measurement: "Average" };
            const min_data = { label: label, measurement: "Min" };
            const max_data = { label: label, measurement: "Max" };

            for (const subplot of selectedParameters) {
                const field = parameters[subplot]["tableField"];
                avg_data[field] = average(plotData[location_node_id][field] ?? []);
                min_data[field] = minimum(plotData[location_node_id][field] ?? []);
                max_data[field] = maximum(plotData[location_node_id][field] ?? []);
            }
            tableData.push(avg_data);
            tableData.push(min_data);
            tableData.push(max_data);
        }

        const table = {
            columns: columns,
            data: tableData,
            keyField: "label",
        };

        return { plot, table };
    }
}

class FifteenMinuteParser {
    static parseData(plotData, metaData) {
        const parameters = {
            Voltage: {
                plotName: "Voltage",
                yAxisName: "Voltage (V)",
                plotFields: [], //Assign in preprocesing section depending on L-N/L-L/DC
                legendFields: [], //""
                traceColor: [], //""
                tableName: "Voltage",
                tableField: "voltage",
            },
            Current: {
                plotName: "Current",
                yAxisName: "Current (A)",
                plotFields: ["current", "current_a", "current_b", "current_c"],
                legendFields: ["Current", "Current-A", "Current-B", "Current-C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Current",
                tableField: "current",
            },
            "Line Frequency": {
                plotName: "Line Frequency",
                yAxisName: "Line Frequency",
                plotFields: ["line_frequency"],
                legendFields: ["Line Frequency"],
                traceColor: ["green"],
                tableName: "Line Frequency",
                tableField: "line_frequency",
            },
            "Power Factor": {
                plotName: "Power Factor",
                yAxisName: "Power Factor",
                plotFields: ["power_factor"],
                legendFields: ["Power Factor"],
                traceColor: ["green"],
                tableName: "Power Factor",
                tableField: "power_factor",
            },
            "Voltage Imbalance": {
                plotName: "Voltage Imbalance",
                yAxisName: "Voltage Imbalance",
                plotFields: ["voltage_imbalance"],
                legendFields: ["Voltage Imbalance"],
                traceColor: ["green"],
                tableName: "Voltage Imbalance",
                tableField: "voltage_imbalance",
            },
            "Current Imbalance": {
                plotName: "Current Imbalance",
                yAxisName: "Current Imbalance",
                plotFields: ["current_imbalance"],
                legendFields: ["Current Imbalance"],
                traceColor: ["green"],
                tableName: "Current Imbalance",
                tableField: "current_imbalance",
            },
            "Voltage THD": {
                plotName: "Voltage THD",
                yAxisName: "Voltage THD",
                plotFields: ["voltage_thd"],
                legendFields: ["Voltage THD"],
                traceColor: ["green"],
                tableName: "Voltage THD",
                tableField: "voltage_thd",
            },
            "Current THD": {
                plotName: "Current THD",
                yAxisName: "Current THD",
                plotFields: ["current_thd"],
                legendFields: ["Current THD"],
                traceColor: ["green"],
                tableName: "Current THD",
                tableField: "current_thd",
            },
            "V-Peaks": {
                plotName: "V-Peaks",
                yAxisName: "Voltage (V)",
                plotFields: ["voltage", "v_peak", "v_peak_avg"],
                legendFields: ["Voltage", "V-Peak", "V-Peak-Avg"],
                traceColor: ["green", "red", "black"],
                tableName: "V-Peaks",
                tableField: "v_peak",
            },
            "GIS-Voltage": {
                plotName: "GIS_Voltage",
                yAxisName: "GIS_Voltage",
                plotFields: ["gis_voltage"],
                legendFields: ["GIS_Voltage"],
                traceColor: ["green"],
                tableName: "GIS_Voltage",
                tableField: "gis_voltage",
            },
            "Crest Factor": {
                plotName: "Crest Factor",
                yAxisName: "Crest Factor",
                plotFields: ["crest_factor"],
                legendFields: ["Crest Factor"],
                traceColor: ["green"],
                tableName: "Crest Factor",
                tableField: "crest_factor",
            },
            HP: {
                plotName: "HP",
                yAxisName: "HP",
                plotFields: ["hp"],
                legendFields: ["HP"],
                traceColor: ["green"],
                tableName: "HP",
                tableField: "hp",
            },
            "V/I": {
                plotName: "V/I",
                yAxisName: "V/I (Ω)",
                plotFields: ["v_i"],
                legendFields: ["V/I"],
                traceColor: ["green"],
                tableName: "V/I",
                tableField: "v_i",
            },
            "Field Voltage": {
                plotName: "Field Voltage",
                yAxisName: "Field Voltage (V)",
                plotFields: ["field_voltage"],
                legendFields: ["Field Voltage"],
                traceColor: ["green"],
                tableName: "Field Voltage",
                tableField: "field_voltage",
            },
            "Field Current": {
                plotName: "Field Current",
                yAxisName: "Field Current (A)",
                plotFields: ["field_current"],
                legendFields: ["Field Current"],
                traceColor: ["green"],
                tableName: "Field Current",
                tableField: "field_current",
            },
            "Raw Power Factor": {
                plotName: "Power Factor",
                yAxisName: "",
                plotFields: ["raw_power_factor"],
                legendFields: ["Power Factor"],
                traceColor: ["green"],
                tableName: "",
                tableField: [],
            },
            "Apparent Power (kVA)": {
                plotName: "Apparent Power (kVA)",
                yAxisName: "kVA",
                plotFields: ["kva"],
                legendFields: ["kVA"],
                traceColor: ["green"],
                tableName: "",
                tableField: [],
            },
            "Reactive Power (kVAR)": {
                plotName: "Reactive Power (kVAR)",
                yAxisName: "kVAR",
                plotFields: ["kvar"],
                legendFields: ["kVAR"],
                traceColor: ["green"],
                tableName: "",
                tableField: [],
            },
            "Active Power (kW)": {
                plotName: "Active Power (kW)",
                yAxisName: "kW",
                plotFields: ["kw"],
                legendFields: ["kW"],
                traceColor: ["green"],
                tableName: "",
                tableField: [],
            },
            "RAW HP": {
                plotName: "HP",
                yAxisName: "HP",
                plotFields: ["raw_hp"],
                legendFields: ["HP"],
                traceColor: ["green"],
                tableName: "",
                tableField: [],
            },
        };

        const selectedParameters = metaData.selectedParameters
            .map((param) => param.value)
            .filter((param) => param in parameters);

        if (!("time" in plotData) || plotData["time"] == null || plotData["time"].length == 0) {
            return {
                plot: {
                    data: [],
                    layout: {},
                },
                table: {
                    data: [],
                    columns: [{ dataField: "", text: "" }],
                    keyField: "measurement",
                },
            };
        }

        //-------Pre-Processing-------//
        for (const param of selectedParameters) {
            if (param == "V/I") {
                //Create V/I data

                const plotField = parameters[param]["plotFields"][0];
                if (plotData["voltage"] == null || plotData["current"] == null) {
                    console.log("Missing data requirements for the V/I plot");
                    continue;
                }

                const v_i = Array(plotData["voltage"].length);
                const v_noise = metaData.selectedEquipment.v_noise;
                const i_noise = metaData.selectedEquipment.i_noise;
                for (var i = 0; i < plotData["voltage"].length; i++) {
                    //Finds V/I and rounds to 3 digits
                    if (plotData["voltage"][i] < v_noise || plotData["current"][i] < i_noise) {
                        v_i[i] = 0;
                    } else {
                        v_i[i] = Math.round((plotData["voltage"][i] / plotData["current"][i]) * 1e3) / 1e3;
                    }
                }
                plotData[plotField] = v_i;
            } else if (param == "Voltage") {
                if (metaData.selectedEquipment.eq_type == "dc") {
                    parameters["Voltage"]["plotFields"] = ["voltage"];
                    parameters["Voltage"]["legendFields"] = ["Voltage DC"];
                    parameters["Voltage"]["traceColor"] = ["black"];
                    parameters["Voltage"]["tableName"] = "Voltage DC";
                } else if (metaData.v_type == "L-N") {
                    parameters["Voltage"]["plotFields"] = ["voltage", "voltage_a", "voltage_b", "voltage_c"];
                    parameters["Voltage"]["legendFields"] = ["Voltage L-N", "Voltage-A", "Voltage-B", "Voltage-C"];
                    parameters["Voltage"]["traceColor"] = ["green", "black", "red", "blue"];
                    parameters["Voltage"]["tableName"] = "Voltage L-N";
                } else if (metaData.selectedEquipment.eq_type_sub == "v1") {
                    parameters["Voltage"]["plotFields"] = ["voltage"];
                    parameters["Voltage"]["legendFields"] = ["Voltage"];
                    parameters["Voltage"]["traceColor"] = ["green"];
                    parameters["Voltage"]["tableName"] = "Voltage";
                } else if (metaData.selectedEquipment.eq_type_sub == "i1") {
                    parameters["Voltage"]["plotFields"] = ["voltage"];
                    parameters["Voltage"]["legendFields"] = ["Voltage"];
                    parameters["Voltage"]["traceColor"] = ["green"];
                    parameters["Voltage"]["tableName"] = "Voltage";
                } else {
                    parameters["Voltage"]["plotFields"] = ["voltage", "voltage_ab", "voltage_bc", "voltage_ca"];
                    parameters["Voltage"]["legendFields"] = ["Voltage L-L", "Voltage-AB", "Voltage-BC", "Voltage-CA"];
                    parameters["Voltage"]["traceColor"] = ["green", "black", "red", "blue"];
                    parameters["Voltage"]["tableName"] = "Voltage L-L";
                }
            } else if (param == "Current") {
                if (metaData.selectedEquipment.eq_type == "dc") {
                    const postfix = " DC";
                    parameters["Current"]["legendFields"][0] += postfix;
                    parameters["Current"]["plotFields"] = parameters["Current"]["plotFields"].slice(0, 1);
                    parameters["Current"]["legendFields"] = parameters["Current"]["legendFields"].slice(0, 1);
                    parameters["Current"]["traceColor"] = parameters["Current"]["traceColor"].slice(0, 1);
                    parameters["Current"]["tableName"] += postfix;
                } else if (metaData.selectedEquipment.eq_type_sub == "i1") {
                    parameters["Current"]["legendFields"] = ["Current"];
                    parameters["Current"]["plotFields"] = ["current"];
                    parameters["Current"]["traceColor"] = ["green"];
                    parameters["Current"]["tableName"] = "Current";
                }
            } else if (param == "V-Peaks") {
                if (metaData.v_type == "L-N") {
                    parameters["V-Peaks"]["legendFields"] = ["Voltage L-N", "V-Peaks L-N", "V-Peaks-Avg L-N"];
                } else {
                    parameters["V-Peaks"]["legendFields"] = ["Voltage L-L", "V-Peaks L-L", "V-Peaks-Avg L-L"];
                }
            }
        }

        //-------Creating plots-------//
        const plotTitle =
            metaData.selectedEquipment.plot_title_node_label +
            ` (${metaData.selectedEquipment.np_voltage}V, ${metaData.selectedEquipment.np_current}A, ${metaData.selectedEquipment.np_rpm}RPM, ${metaData.selectedEquipment.np_hp}HP)` +
            "<br>Date: " +
            metaData.startDate +
            " to " +
            metaData.endDate +
            " " +
            new Date().toLocaleDateString("en-US", { timeZone: metaData.timezone, timeZoneName: "short" }).slice(-3);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: "Date - Time" },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: { font: { size: 22 }, automargin: true, x: 0.07, align: "top", text: plotTitle },
            legend: { font: { size: 18 } },
            showlegend: true,
        };

        const x = plotData.time;

        var data = [];
        var annotations = [];
        var count = 0;

        for (const subplot of selectedParameters) {
            if (plotData[parameters[subplot]["plotFields"][0]] == null) continue;

            count++;

            //Format if Y-axis name is too long
            //Replace ignores any html tags so only display name is measured
            var yTitle = parameters[subplot]["yAxisName"];
            if (yTitle.replace(/(<([^>]+)>)/gi, "").length > 20) {
                //replace the space at arbitrary point near middle of text with a break
                const index = yTitle.replace(/(<([^>]+)>)/gi, "").indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                yTitle = yTitle.substring(0, index) + "<br>" + yTitle.substring(index + 1);
            }

            layout[`yaxis${count}`] = {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: yTitle },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            };

            const subplotTitle = {
                text: parameters[subplot]["plotName"],
                font: { size: 18 },
                xref: "paper",
                yref: `y${count} domain`,
                x: 0.5,
                y: 0.99,
                yanchor: "bottom",
                showarrow: false,
            };
            annotations.push(subplotTitle);
            for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                if (plotData[field] == null) continue;

                const trace = {
                    x: x,
                    y: plotData[field],
                    yaxis: `y${count}`,
                    type: "scatter",
                    name: parameters[subplot]["legendFields"][idx],
                    marker: { color: parameters[subplot]["traceColor"][idx] },
                    legendgroup: parameters[subplot]["legendFields"][idx],
                    showlegend: true,
                };
                const fillerTrace = {
                    x: x,
                    y: plotData[field],
                    yaxis: `y${count}`,
                    type: "scatter",
                    name: parameters[subplot]["legendFields"][idx],
                    marker: { color: parameters[subplot]["traceColor"][idx] },
                    legendgroup: parameters[subplot]["legendFields"][idx],
                    showlegend: false,
                    line: {
                        dash: "2px,4px",
                        width: "2",
                    },
                    connectgaps: true,
                };
                data.push(trace, fillerTrace);

                //Add NP + SF trace for current plot
                if (field == "current") {
                    const eq = metaData.selectedEquipment;
                    const yVal = parseFloat((eq.np_sf == 0.1 ? 1.15 : eq.np_sf) * eq.np_current);
                    const npsf_trace = {
                        x: [x[0], x.slice(-1)[0]],
                        y: [yVal, yVal],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: "NP + SF",
                        marker: { color: "black" },
                    };
                    data.push(npsf_trace);
                }
            }
        }

        layout["grid"] = { rows: count, columns: 1 };
        layout["height"] = count == 1 ? 400 : count * 300;
        layout["annotations"] = annotations;

        const plot = { layout, data };

        //----Creating Table----//
        const columns = [{ dataField: "measurement", text: "" }];

        const avg_data = { measurement: "Average" };
        const min_data = { measurement: "Min" };
        const max_data = { measurement: "Max" };

        for (const subplot of selectedParameters) {
            const field = parameters[subplot]["tableField"];
            if (plotData[field] == null) continue;

            const text = parameters[subplot]["tableName"];
            columns.push({ dataField: field, text: text });
            avg_data[field] = average(plotData[field]);
            min_data[field] = minimum(plotData[field]);
            max_data[field] = maximum(plotData[field]);
        }

        const tableData = [avg_data, min_data, max_data];

        const table = {
            columns: columns,
            data: tableData,
            keyField: "measurement",
        };

        return { plot, table };
    }
}

class AccumulatedParser {
    static parseAmpOverSF(plotData, selectedEquipment, plotWithEstimationFlag, metaData) {
        const ampOverSFData = plotData?.amp_over_sf_daily;

        if (!ampOverSFData || Object.keys(ampOverSFData).length === 0 || selectedEquipment == null)
            return { data: [], layout: {} };

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: "Date - Time" },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            yaxis: {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: "Amp hours" },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: { font: { size: 22 }, x: 0.07, align: "top", text: "Amp hours over Service Factor" },
            legend: { font: { size: 18 } },
            showlegend: true,
            grid: { rows: 1, columns: 1 },
            colorway: ["blue", "red", "orange", "green", "purple"],
        };

        //Add equipment metaData to API response data for convenience
        for (const eq of selectedEquipment) {
            const location_node_id = eq["location_node_id"];

            if (ampOverSFData[location_node_id] == null) continue;

            ampOverSFData[location_node_id]["motor_change_epochs"] = eq["motor_change_epoch"];
            ampOverSFData[location_node_id]["label"] = eq["label"];
        }

        const data = [];
        for (const [location_node_id, nodeData] of Object.entries(ampOverSFData)) {
            if (nodeData == null || nodeData.day.length <= 0) continue;

            const x = [];
            const y = [];

            //motor change epochs are given in seconds, Date() constructor takes ms
            //ISO string is the best way to represent dates, slice(0,10) gives just year, month, day -> yyyy-mm-dd
            const motorChangeDates = Array.isArray(nodeData.motor_change_epochs)
                ? nodeData.motor_change_epochs.map((epoch) => new Date(epoch * 1000).toISOString().slice(0, 10))
                : [];

            var motorChangeIdx = 0;
            var runningTotal = 0;

            //If the first motor change occurs before we have data -> create estimated data based on first 10 days after recorded data
            // if(plotWithEstimationFlag == true && nodeData.motor_change_epochs.length > 0 && motorChangeDates[0] < nodeData['amp_over_sf'].day[0]){
            // 	const x1 = new Date(nodeData['amp_over_sf']['day'][0]).getTime() / 1000 //convert milliseconds to seconds
            // 	const x2 = new Date(nodeData['amp_over_sf']['day'][10]).getTime() / 1000
            // 	const y1 = nodeData['amp_over_sf']['amp_hours_sf'][0]
            // 	const y2 = nodeData['amp_over_sf']['amp_hours_sf'].slice(0,11).reduce((accumulator, currentValue) => accumulator + currentValue,0)

            // 	const slope = (y2 - y1) / (x2 - x1)

            // 	//Add first day beginning at the first motor change
            // 	x.push(motorChangeDates[0])
            // 	y.push(0)

            // 	motorChangeIdx = 1
            // 	while(motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < nodeData['amp_over_sf']['day'][0]){
            // 		const estimation = slope * (nodeData.motor_change_epochs[motorChangeIdx] - nodeData.motor_change_epochs[motorChangeIdx - 1])

            // 		//Add the estimation and then 0 for the next day after the motor has been changed
            // 		x.push(motorChangeDates[motorChangeIdx])
            // 		y.push(estimation)

            // 		const d = new Date(motorChangeDates[motorChangeIdx])
            // 		d.setUTCDate(d.getUTCDate()+1)
            // 		x.push(d.toISOString().slice(0,10))
            // 		y.push(0)

            // 		motorChangeIdx++;
            // 	}

            // 	//Add the estimation that occurs between the last motor change that occurs before data start and the day before data start
            // 	const estimation = slope * ((x1 - 86400) - nodeData.motor_change_epochs[motorChangeIdx - 1])

            // 	const d = new Date(x1 * 1000)
            // 	d.setUTCDate(d.getUTCDate() - 1)

            // 	x.push(d.toISOString().slice(0,10))
            // 	y.push(estimation)
            // 	runningTotal += estimation
            // }else{
            //If no motor change occurs before data starts - add a 0 on day before data starts for better plotting
            const day0 = new Date(nodeData["day"][0]);
            day0.setUTCDate(day0.getUTCDate() - 1);
            x.push(day0.toISOString().slice(0, 10));
            y.push(0);

            //Move motorIdx to correct spot if plotting w/o estimation
            while (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < nodeData.day[0])
                motorChangeIdx++;
            //}

            //Plotting once data has started
            //Calc and add running total for each day in data - reset if motor change occurs
            var dayIdx = 0;
            while (dayIdx < nodeData.day.length) {
                const amp_hours_sf = nodeData.amp_hours_sf[dayIdx];
                const day = nodeData.day[dayIdx];

                if (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < day) {
                    //Reset on motor change
                    runningTotal = 0;
                    x.push(motorChangeDates[motorChangeIdx]);
                    y.push(0);
                    motorChangeIdx++;
                }

                runningTotal += amp_hours_sf;
                x.push(day);
                y.push(runningTotal);

                dayIdx++;
            }

            const trace = {
                x: x,
                y: y,
                yaxis: `y`,
                type: "scatter",
                name: nodeData["label"],
                mode: "lines+markers",
            };
            data.push(trace);
        }

        return { layout, data };
    }

    static parseStarts(plotData, selectedEquipment, plotWithEstimationFlag, metaData) {
        const startsData = plotData?.starts_daily;

        if (!startsData || Object.keys(startsData).length === 0 || selectedEquipment == null)
            return { data: [], layout: {} };

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: "Date - Time" },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            yaxis: {
                title: { font: { size: 22 }, standoff: 20, x: 0.0, text: "Starts" },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: { font: { size: 22 }, x: 0.07, align: "top", text: "Accumulation of Starts" },
            legend: { font: { size: 18 } },
            showlegend: true,
            colorway: ["blue", "red", "orange", "green", "purple"],
        };

        //Add equipment metaData to API response data for convenience
        for (const eq of selectedEquipment) {
            const location_node_id = eq["location_node_id"];

            if (startsData[location_node_id] == null) continue;

            startsData[location_node_id]["motor_change_epochs"] = eq["motor_change_epoch"];
            startsData[location_node_id]["label"] = eq["label"];
        }

        const data = [];
        for (const [location_node_id, nodeData] of Object.entries(startsData)) {
            if (nodeData == null || nodeData.day.length <= 0) continue;

            const x = [];
            const y = [];

            //motor change epochs are given in seconds, Date() constructor takes ms
            //ISO string is the best way to represent dates, slice(0,10) gives just year, month, day -> yyyy-mm-dd
            const motorChangeDates = Array.isArray(nodeData.motor_change_epochs)
                ? nodeData.motor_change_epochs.map((epoch) => new Date(epoch * 1000).toISOString().slice(0, 10))
                : [];

            var motorChangeIdx = 0;
            var runningTotal = 0;

            //If the first motor change occurs before we have data -> create estimated data based on first 10 days after recorded data
            // if(plotWithEstimationFlag == true && nodeData.motor_change_epochs.length > 0 && motorChangeDates[0] < nodeData['starts'].day[0]){
            // 	const x1 = new Date(nodeData['starts']['day'][0]).getTime() / 1000 //convert milliseconds to seconds
            // 	const x2 = new Date(nodeData['starts']['day'][10]).getTime() / 1000
            // 	const y1 = nodeData['starts']['starts'][0]
            // 	const y2 = nodeData['starts']['starts'].slice(0,11).reduce((accumulator, currentValue) => accumulator + currentValue,0)

            // 	const slope = (y2 - y1) / (x2 - x1)

            // 	//Add first day beginning at the first motor change
            // 	x.push(motorChangeDates[0])
            // 	y.push(0)

            // 	motorChangeIdx = 1
            // 	while(motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < nodeData['starts']['day'][0]){
            // 		const estimation = Math.round(slope * (nodeData.motor_change_epochs[motorChangeIdx] - nodeData.motor_change_epochs[motorChangeIdx - 1]))

            // 		//Add the estimation and then 0 for the next day after the motor has been changed
            // 		x.push(motorChangeDates[motorChangeIdx])
            // 		y.push(estimation)

            // 		const d = new Date(motorChangeDates[motorChangeIdx])
            // 		d.setUTCDate(d.getUTCDate()+1)
            // 		x.push(d.toISOString().slice(0,10))
            // 		y.push(0)

            // 		motorChangeIdx++;
            // 	}

            // 	//Add the estimation that occurs between the last motor change that occurs before data start and the day before data start
            // 	const estimation = Math.round(slope * ((x1 - 86400) - nodeData.motor_change_epochs[motorChangeIdx - 1]))

            // 	const d = new Date(x1 * 1000)
            // 	d.setUTCDate(d.getUTCDate() - 1)

            // 	x.push(d.toISOString().slice(0,10))
            // 	y.push(estimation)
            // 	runningTotal += estimation
            // }else{
            //If no motor change occurs before data starts - add a 0 on day before data starts for better plotting
            const day0 = new Date(nodeData["day"][0]);
            day0.setUTCDate(day0.getUTCDate() - 1);
            x.push(day0.toISOString().slice(0, 10));
            y.push(0);

            //Move motorIdx to correct spot if plotting w/o estimation
            while (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < nodeData.day[0])
                motorChangeIdx++;
            //}

            //Plotting once data has started
            //Calc and add running total for each day in data - reset if motor change occurs
            var dayIdx = 0;
            while (dayIdx < nodeData.day.length) {
                const starts = nodeData.starts[dayIdx];
                const day = nodeData.day[dayIdx];

                if (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < day) {
                    //Reset on motor change
                    runningTotal = 0;
                    x.push(motorChangeDates[motorChangeIdx]);
                    y.push(0);
                    motorChangeIdx++;
                }

                runningTotal += starts;
                x.push(day);
                y.push(runningTotal);

                dayIdx++;
            }

            const trace = {
                x: x,
                y: y,
                yaxis: `y`,
                type: "scatter",
                name: nodeData["label"],
                mode: "lines+markers",
            };
            data.push(trace);
        }

        return { layout, data };
    }
}

class RelayParser {
    static parameters = {
        //Dictionary of keys/fields to search for
        Voltage: {
            plotFields: ["v_avg_ll"],
            legendFields: ["Voltage"],
            tableField: "v_avg_ll",
            traceColor: "blue",
        },
        Current: {
            plotFields: ["i_avg"],
            legendFields: ["Current"],
            tableField: "i_avg",
            traceColor: "orange",
        },
        "Line Frequency": {
            plotFields: ["line_frequency"],
            legendFields: ["Line Frequency"],
            tableField: "line_frequency",
            traceColor: "green",
        },
        RPM: {
            plotFields: ["rpm"],
            legendFields: ["RPM"],
            tableField: "rpm",
            traceColor: "green",
        },
    };

    /*
	Inputs: 
		plotData: plot_data object returned within the 'getHourlyTrendData' api call
		plotMetaData: metaData containing selectedEquipment, startDate, endDate, and selectedVoltageType.value - Typically set in tabData and given to the plot as a prop
	Outputs:
		Object containing layout and data parameters to be used by a plotly plot
	*/
    static getPlotResponse(plotData, plotMetaData) {
        //console.log(plotData)
        const parameters = this.parameters;
        const plotTitle =
            plotMetaData.selectedEquipment.plot_title_node_label +
            ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
            "<br>Date: " +
            plotMetaData.startDate +
            " to " +
            plotMetaData.endDate +
            " " +
            new Date()
                .toLocaleDateString("en-US", {
                    timeZone: plotMetaData.timezone,
                    timeZoneName: "short",
                })
                .slice(-3);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                align: "top",
                text: plotTitle,
            },
            legend: { font: { size: 18 } },
            showlegend: true,
        };

        const markerColors = ["green", "black", "red", "blue"];
        const x = plotData.time;

        var data = [];
        var annotations = [];
        var count = 0;

        for (const subplot in parameters) {
            if (
                plotData[parameters[subplot]["plotFields"][0]] == null ||
                plotData[parameters[subplot]["plotFields"][0]].length <= 0
            )
                continue;

            count++;

            //Format if Y-axis name is too long
            var yTitle = subplot;
            if (yTitle.length > 20) {
                //replace the space at arbitrary point near middle of text with a \n
                const index = yTitle.indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                yTitle = subplot.substring(0, index) + "<br>" + subplot.substring(index + 1);
            }

            layout[`yaxis${count}`] = {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: yTitle,
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            };

            const subplotTitle = {
                text: subplot,
                font: { size: 18 },
                xref: "paper",
                yref: `y${count} domain`,
                x: 0.5,
                y: 0.99,
                yanchor: "bottom",
                showarrow: false,
            };
            annotations.push(subplotTitle);
            for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                if (plotData[field] == null || plotData[field].length <= 0) continue;

                const trace = {
                    x: x,
                    y: plotData[field],
                    yaxis: `y${count}`,
                    type: "scatter",
                    name: parameters[subplot]["legendFields"][idx],
                    marker: { color: parameters[subplot]["traceColor"] },
                    connectgaps: true,
                };
                data.push(trace);

                //Add NP + SF trace for current plot
                if (field == "i_avg") {
                    const eq = plotMetaData.selectedEquipment;
                    const yVal = parseFloat((eq.np_sf == 0.1 ? 1.15 : eq.np_sf) * eq.np_current);
                    const npsf_trace = {
                        x: [x[0], x.slice(-1)[0]],
                        y: [yVal, yVal],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: "NP + SF",
                        marker: { color: "black" },
                    };
                    data.push(npsf_trace);
                }
            }
        }

        layout["grid"] = { rows: count, columns: 1 };
        layout["height"] = count == 1 ? 400 : count * 300;
        layout["annotations"] = annotations;
        return { layout, data };
    }

    /*
	Inputs: Raw table data from 'getHourlyTrendData' api call 
	Outputs: Object containing columns, table data, with a keyfield for a bootstrap table
	*/
    static getTableResponse(tableData) {
        const parameters = this.parameters;

        const columns = [{ dataField: "measurement", text: "" }];

        const avg_data = { measurement: "Average" };
        const min_data = { measurement: "Min" };
        const max_data = { measurement: "Max" };

        for (const subplot in parameters) {
            const field = parameters[subplot]["tableField"];
            const avg = tableData[`${field}_avg`];
            const min = tableData[`${field}_min`];
            const max = tableData[`${field}_max`];
            if (avg != null) {
                columns.push({ dataField: field, text: subplot });
                avg_data[field] = avg;
                min_data[field] = min;
                max_data[field] = max;
            }
        }

        const data = Object.keys(avg_data).length > 1 ? [avg_data, min_data, max_data] : [];

        return {
            columns,
            data,
            keyField: "measurement",
        };
    }
}

export { RealTimeMeringPlotParser, OneHourParser, OneSecParser, FifteenMinuteParser, AccumulatedParser, RelayParser };
